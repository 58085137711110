....<template>
  <div>
    <v-card>
      <v-card-text>
        <v-row>
          <v-col
            cols="6"
            sm="6"
            md="6">
            <h1> เลขที่บิลการสั่งซื้อ</h1>
          </v-col>
          <v-col
            v-if="link_payment"
            cols="6"
            sm="6"
            md="6">
            <v-textarea
              label=""
              id="myInput"
              v-model="link_payment"></v-textarea>

            <v-btn
              color="primary"
              @click="copy">คัดลอกลิ้งก์</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card class="mt-5">
      <v-card-text class="mb-2">
        <v-select
          v-model="order_id"
          :items="orders"
          @change="orderSelect"
          item-text="order_inv"
          item-value="value"
          label="เลขที่บิลการสั่งซื้อ"
        ></v-select>
      </v-card-text>
    </v-card>

    <v-card v-if="details.length > 0">
      <v-card-text>
        <v-row>
          <v-col
            cols="12">
            <table class="table">
              <thead>
              <tr>
                <th width="50" class="text-center">#</th>
                <th>รหัสลาย</th>
                <th>ชื่อลาย</th>
                <th>ยี่ห้อสินค้า</th>
                <th>รุ่นสินค้า</th>
                <th>ราคา (บาท)</th>
                <th>จำนวน (ชิ้น)</th>
                <th>ราคารวม (บาท)</th>
              </tr>
              </thead>
              <tbody>
              <!--            pattern_set_prices-->
              <tr v-for="(item,index) in details">
                <td class="text-center"> {{index+1}}</td>
                <td>
                  {{ item.pattern ? item.pattern.pattern_code :'-'}}
                </td>
                <td>

                  {{ item.pattern ?item.pattern.pattern_name : '-'}}
                </td>
                <td>
                  {{ item.product && item.product.brand ? item.product.brand.phone_brand_name : '' }}
                </td>
                <td>

                  {{ item.product && item.product.phone_model ? item.product.phone_model.phone_model_name : ''}}
                </td>
                <td class="text-center">

                  {{ item.product_price }}
                </td>
                <td class="text-center">

                  {{ item.product_amount }}
                </td>
                <td class="text-center">

                  {{ item.product_price  * item.product_amount }}
                </td>
              </tr>
              </tbody>
              <tfoot>
              <tr>
                <th colspan="6" class="text-center">รวม</th>
                <th>{{  sum_quantity() }}</th>
                <th>{{  sum_total()  }}</th>
              </tr>
              <tr>
                <th colspan="6" class="text-center">วิธีการส่งสืนค้า</th>
                <th> {{ editedItem.transport_type.transport_type_name }}</th>
                <th> {{ editedItem.transport_expense_price }}</th>
              </tr>
              <tr>
                <th colspan="6" class="text-center"></th>
                <th>ส่วนลด(บาท)</th>
                <th> {{ editedItem.order_price_discount }} </th>
              </tr>
              <tr>
                <th colspan="6" class="text-center">รวมสุทธิ</th>
                <th></th>
                <th> {{ editedItem.order_expense }} (บาท)</th>
              </tr>
              </tfoot>
            </table>
          </v-col>
        </v-row>
      </v-card-text>


    </v-card>
  </div>
</template>

<script>
import {mdiPencil, mdiDelete} from '@mdi/js'
// import config from '../../../config'
 import instance_payment from '../../../services/payment'

export default {
  setup() {
    return {
      icons: {
        mdiPencil,
        mdiDelete,
      },
    }
  },
  data: () => ({
    details: [],
    orders : [],
    link_payment : '',
    dialog: false,
    dialogDelete: false,
    search: '',
    order_id : '',
    headers: [
      {
        text: 'ลำดับ',
        value: 'index'
      },
      {text: 'ชื่อตั้งค่า', value: 'name'},
      {text: 'จำนวน/ค่า', value: 'value'},
      {text: 'สถานะ (เปิด/ปิด)', value: 'status'},
      {text: 'แก้ไขข้อมูล', value: 'actions'},
    ],
    reports: [
      {
        status: 'รอการจัดส่ง',
        total : 100,
        icon : '',
        count : 5,
        url : ''
},
    ],
    editedIndex: -1,
    editedItem: {
      productPoint_id: '',
      image_path: '',
      productPoint_name: '',
      detail: '',
      stock: 0,
      amount: 0,
      score: 0,
    },
    defaultItem: {
      productPoint_id: '',
      image_path: '',
      productPoint_name: '',
      detail: '',
      stock: 0,
      amount: 0,
      score: 0,
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'เพิ่ม ตั้งค่าใช้จ่ายใน Order' : 'แก้ไข ตั้งค่าใช้จ่ายใน Order'
    },
  },

  watch: {
    // dialog(val) {
    //   val || this.close()
    // },
    // dialogDelete(val) {
    //   val || this.closeDelete()
    // },
  },

  created() {
    // this.initialize()
  },
  mounted() {

    this.get_orderPaymentAPI();
  },

  methods: {
    sum_quantity(){
      var sum =0;
      for(var i=0;i < this.details.length;i++){
        sum += this.details[i].product_amount;
      }
      return sum;
    },
    sum_total(){
      var sum =0;
      for(var i=0;i < this.details.length;i++){
        sum += (this.details[i].product_amount * this.details[i].product_price );
      }
      return sum;

    },
    editItem(item) {
      // this.editedIndex = this.configs.indexOf(item)
      // this.editedItem = {...item}
      // this.dialog = true

    },
    select_order(){
      this.order_id = '';
    },
    updateStatus(id) {
      // instance_config.updateStatus(id).then(res => {
      //
      // }).catch(err => {
      //
      // });
    },
    // deleteItemConfirm() {
    //   instance_config.packageDestroy(this.editedItem).then(res => {
    //
    //     this.configs.splice(this.editedIndex, 1)
    //   }).catch(err => {
    //
    //   })
    //   this.closeDelete()
    // },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = {...this.defaultItem}
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = {...this.defaultItem}
        this.editedIndex = -1
      })
    },

    save() {

      // var vm = this;
      // let idx = vm.editedIndex
      // let item = vm.editedItem
      // if (this.editedIndex > -1) {
      //   // instance_config.put(this.editedItem)
      //   //   .then(res => {
      //   Object.assign(vm.configs[idx], item)
      //   // this.getConfig()
      //   // this.close()
      //   // }).catch(err => {
      //   //
      //   // });
      // } else {
      //   instance_config.post(this.editedItem)
      //     .then(res => {
      //       this.getConfig()
      //       this.close()
      //       // this.configs.push(item)
      //     }).catch(err => {
      //
      //   });
      // }
    },
    copy() {
      /* Get the text field */
      var copyText = document.getElementById("myInput");

      /* Select the text field */
      copyText.select();
      copyText.setSelectionRange(0, 99999); /*For mobile devices*/

      /* Copy the text inside the text field */
      document.execCommand("copy");

      /* Alert the copied text */
      alert("คัดลอกลิ้งค์: " + copyText.value);
    },
    orderSelect() {
      this.$store.state.isLoading = true
      instance_payment.getOrderPaymentDetail(this.order_id).then(res => {
        this.editedItem = res.data.order;
        this.details = res.data.order_details;
        this.link_payment =
          ''+
          res.data.url_payment + '\n'+
          '🔺กดลิ้งค์ด้านบนนี้เพื่อชำระค่าสินค้า\n' +
          '1.แบบโอนเงิน\n' +
          '2.แบบหักผ่านบัตรเดบิต/เครดิต\n' +
          '\n' +
          'ขั้นตอนหลังการโอนเงินเสร็จแล้ว..\n' +
          '1. ลิงค์ด้านบนที่ร้านส่งไป ลูกค้าเข้าไปเช็ครายละเอียดความถูกต้องของชื่อรุ่นโทรศัพท์ และลายที่ได้สั่งนะค้าบ\n' +
          '2. รอรับของจากขนส่งได้เลยครับใน 2-4 วันทำการ\n' +
          '3. หากสินค้ายังไม่ได้รับเกิน 1 อาทิตย์ ให้เช็คที่ตู้ไปรษณีย์ของตัวเองจะมีใบรับพัสดุทิ้งไว้ ให้ไปรับก่อน 14 วันทำการไม่งั้นของจะตีกลับมาหาทางร้านนะครับ\n' +
          '\n' +
          'ลิงค์จะเปลี่ยนเป็นจัดส่งตอนไหน?..\n' +
          '1. เมื่อร้านได้รับยอดโอนเงินแล้ว 1-2 วันลิงค์จะเปลี่ยนเป็นจัดส่งของ\n' +
          '2. เมื่อเปลี่ยนเป็นจัดส่งแล้วจะมีเลขพัสดุขึ้น ตัวอย่าง.. EV XXXXXXXX TH\n' +
          '3. ลูกค้าเอาเลขที่ได้รับไปกรอก-เช็คในเว็บไซต์ ' +'https://track.thailandpost.co.th'+'\n' +
          ''+
          '4. ลูกค้าจะทราบสถานะของว่าสินค้าอยู่ที่ไหน ขั้นตอนอะไร ของส่งหรือยังไม่ส่งจะมีแจ้งไว้หมดครับ\n' +
          '5. หลังจากนั้น..ไปรษณีย์ไทยจะนำจ่ายพัสดุสินค้าให้ลูกค้าถึงหน้าบ้านครับ\n' +
          '\n' +
          'เมื่อได้รับของแล้ว..\n' +
          '1. ลูกค้าเช็คว่าใส่ได้ใส่ไม่ได้ทันที สินค้าจะเคลมได้ภายใน 7-14 วันตามปัญหาที่พบกับตัวเคส\n' +
          '2. เพื่อกำลังใจในการผลิตเคสของพี่ต่อไป รบกวนลูกค้ารีวิวกลับมาเป็นน้ำใจดีๆ ให้กับพี่ด้วยนะค้าบผม ขอบคุณมากๆ เลยค้าบ'

        ;
        this.$store.state.isLoading = false
      }).catch(err => {
        this.$store.state.isLoading = false

      });

      // axios.get('{{url('admin/get_order_payment')}}' + '/' + this.order_select.value)
    // .then(response => {

      // })
      //   .catch(error => {
      //     console.log(error.message);
      //   });
    },
    get_orderPaymentAPI() {
      this.$store.state.isLoading = true
      instance_payment.getOrderPayment().then(res => {
        this.orders = res.data;
        this.$store.state.isLoading = false
      }).catch(err => {
        this.$store.state.isLoading = false

      })
    },
  },
}
</script>

<style scoped>
hr {
  background-color: #e5e5e5;
  height: 1px;
  border: none;
}
</style>
